import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';

const Home = () => {
  const { token } = useContext(AuthContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [cards, setCards] = useState([]);
  const [filteredCards, setFilteredCards] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch all cards from backend API
    const fetchCards = async () => {
      try {
        const response = await axios.get('https://ecard.bildastudio.com/api/cards', {
          headers: { 'x-auth-token': token },
          withCredentials: true
        });
        setCards(response.data);
      } catch (error) {
        console.error('Error fetching cards:', error);
      }
    };
    fetchCards();
  }, [token]);

  useEffect(() => {
    if (searchTerm.trim() === '') {
      setFilteredCards([]);
    } else {
      const keywords = searchTerm.toLowerCase().split(' ').filter(Boolean);
      const results = cards.filter(card =>
        keywords.every(keyword =>
          Object.values(card.contactInfo).join(' ').toLowerCase().includes(keyword) ||
          card.customFields.some(field => field.value.toLowerCase().includes(keyword))
        )
      );
      setFilteredCards(results);
    }
  }, [searchTerm, cards]);

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this card?')) {
      try {
        await axios.delete(`https://ecard.bildastudio.com/api/cards/${id}`, {
          headers: { 'x-auth-token': token },
          withCredentials: true
        });
        setFilteredCards(filteredCards.filter(card => card._id !== id));
      } catch (error) {
        console.error('Error deleting card:', error);
      }
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-4xl font-bold mb-6 text-center text-gray-800">eCard Platform</h1>

      <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
        <h2 className="text-2xl mb-4 text-gray-700">Search Cards</h2>
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Search by name, email, phone, etc."
        />
      </div>

      <div className="flex justify-end mb-6">
        <button 
          className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg transition duration-300"
          onClick={() => navigate('/create-card')}
        >
          Create New Card
        </button>
        <span className="mx-2"></span>
        <button 
          className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg transition duration-300"
          onClick={() => navigate('/bulk-create')}
        >
          Bulk Create
        </button>
      </div>

      {filteredCards.length > 0 && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredCards.map((card) => (
            <div key={card._id} className="bg-white shadow-lg rounded-lg p-6">
              <>
                {card.contactInfo.name && (
                  <h3 className="text-xl font-semibold mb-2 text-gray-800">{card.contactInfo.name} {card.contactInfo.surname}</h3>
                )}
                {card.contactInfo.organization && (
                  <p className="text-gray-600"><strong>{card.contactInfo.organization}</strong></p>
                )}
                {card.contactInfo.jobTitle && (
                  <p className="text-gray-600"><strong>Job Title:</strong> {card.contactInfo.jobTitle}</p>
                )}
                {card.contactInfo.email && (
                  <p className="text-gray-600"><strong>Email:</strong> {card.contactInfo.email}</p>
                )}
                {card.contactInfo.phone && (
                  <p className="text-gray-600"><strong>Phone:</strong> {card.contactInfo.phone}</p>
                )}
                {card.contactInfo.address && (
                  <p className="text-gray-600"><strong>Address:</strong> {card.contactInfo.address}</p>
                )}
                {card.customFields.map(field => (
                  field.value && (
                    <p key={field._id} className="text-gray-600">
                      <strong>{field.label}:</strong> 
                      <a 
                        href={field.value.startsWith('http') ? field.value : `http://${field.value}`} 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="text-blue-500 hover:underline"
                      >
                        {field.value}
                      </a>
                    </p>
                  )
                ))}
                <div className="flex justify-between mt-4">
                  <button
                    className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-lg transition duration-300"
                    onClick={() => handleDelete(card._id)}
                  >
                    Delete
                  </button>
                  <button
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg transition duration-300"
                    onClick={() => window.location.href = `/edit/${card._id}`}
                  >
                    Edit
                  </button>
                  <button
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg transition duration-300"
                    onClick={() => window.location.href = `https://ecard.bildastudio.com/api/static/${card._id}/${card._id}.html`}
                  >
                    Go To Card
                  </button>
                  <button
  className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg transition duration-300"
  onClick={async () => {
    try {
      await navigator.clipboard.writeText(`https://ecard.bildastudio.com/api/static/${card._id}/${card._id}.html`);
      const button = document.querySelector('#copyButton');
      button.innerHTML = '✔️';
      button.classList.add('bg-green-700');
      setTimeout(() => {
        button.innerHTML = 'Copy Link';
        button.classList.remove('bg-green-700');
      }, 2000);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }}
  id="copyButton"
>
  Copy Link
</button>
                </div>
              </>
            </div>
          ))}
        </div>
      )}

      {filteredCards.length === 0 && searchTerm && (
        <p className="text-center text-gray-500">No cards match your search.</p>
      )}
    </div>
  );
};

export default Home;