import React, { useState, useContext } from 'react';
import axiosInstance from '../axiosConfig';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';
import cover1 from '../assets/images/orgcovers/generic-cover.jpg';
import cover2 from '../assets/images/orgcovers/coverfsi.jpg';

// Importa tutte le altre immagini qui

const coverImages = [
  { name: 'Generic', src: cover1 },
  { name: 'Ferrovie', src: cover2 },
  // Aggiungi tutte le altre immagini qui
];

const BulkCreateCard = () => {
  const { token } = useContext(AuthContext);
  const [file, setFile] = useState(null);
  const [previewData, setPreviewData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [imageFiles, setImageFiles] = useState([]);
  const [selectedCover, setSelectedCover] = useState('');
  const [uploadResults, setUploadResults] = useState([]);
  const navigate = useNavigate();

  const onDrop = (acceptedFiles) => {
    setFile(acceptedFiles[0]);
    readFile(acceptedFiles[0]);
  };

  const onImageDrop = (acceptedFiles) => {
    setImageFiles(acceptedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: '.csv, .xlsx, .xls',
  });

  const { getRootProps: getImageRootProps, getInputProps: getImageInputProps } = useDropzone({
    onDrop: onImageDrop,
    accept: 'image/*',
  });

  const readFile = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      const headers = json[0];
      const rows = json.slice(1);
      const cardsData = rows.map((row) => {
        const card = {};
        headers.forEach((header, index) => {
          card[header] = row[index];
        });
        return card;
      });

      setPreviewData(cardsData);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleUpload = async () => {
    setIsLoading(true);
    setProgress(0);
    setUploadResults([]);

    let createdCount = 0;
    let errors = [];

    for (const card of previewData) {
      const formData = new FormData();
      formData.append('contactInfo', JSON.stringify({
        name: card.name,
        surname: card.surname,
        jobTitle: card.jobTitle,
        organization: card.organization,
        email: card.email,
        phone: card.phone,
        address: card.address
      }));
      formData.append('customFields', JSON.stringify([])); // Handle custom fields if needed

      // Append profilePic and coverPic if they exist
      const profilePicFile = imageFiles.find(file => file.name === card.profilePic);

      if (profilePicFile) {
        formData.append('profilePic', profilePicFile);
      }

      if (selectedCover) {
        formData.append('coverPic', selectedCover);
      } else {
        const coverPicFile = imageFiles.find(file => file.name === card.coverPic);
        if (coverPicFile) {
          formData.append('coverPic', coverPicFile);
        }
      }

      try {
        await axiosInstance.post('/api/cards', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-auth-token': token,
          },
        });
        createdCount++;
      } catch (error) {
        errors.push({ card, error });
      }

      setProgress(((createdCount + errors.length) / previewData.length) * 100);
    }

    setUploadResults({ created: createdCount, errors });
    setIsLoading(false);
  };

  const handleCoverSelectChange = (e) => {
    setSelectedCover(e.target.value);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-4xl font-bold mb-4">Bulk Create Cards</h1>
      <div {...getRootProps({ className: 'dropzone' })} className="border-dashed border-2 border-gray-300 p-4 text-center cursor-pointer mb-4">
        <input {...getInputProps()} />
        {file ? <p>{file.name}</p> : 'Drag & drop a CSV or Excel file, or click to select'}
      </div>
      <div {...getImageRootProps({ className: 'dropzone' })} className="border-dashed border-2 border-gray-300 p-4 text-center cursor-pointer mb-4">
        <input {...getImageInputProps()} />
        {imageFiles.length > 0 ? <p>{imageFiles.map(file => file.name).join(', ')}</p> : 'Drag & drop profile and cover pictures, or click to select'}
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Or select a predefined cover</label>
        <select
          value={selectedCover}
          onChange={handleCoverSelectChange}
          className="w-full p-2 border border-gray-300 rounded"
        >
          <option value="">Select a cover</option>
          {coverImages.map((cover, index) => (
            <option key={index} value={cover.src}>
              {cover.name}
            </option>
          ))}
        </select>
      </div>
      {previewData.length > 0 && (
        <div>
          <h2 className="text-2xl mb-4">Preview</h2>
          <p>{previewData.length} cards found.</p>
          <div className="overflow-auto max-h-64">
            {previewData.map((card, index) => (
              <div key={index} className="mb-2 p-2 border-b border-gray-200">
                <p><strong>Name:</strong> {card.name}</p>
                <p><strong>Surname:</strong> {card.surname}</p>
                <p><strong>Job Title:</strong> {card.jobTitle}</p>
                <p><strong>Organization:</strong> {card.organization}</p>
                <p><strong>Email:</strong> {card.email}</p>
                <p><strong>Phone:</strong> {card.phone}</p>
                <p><strong>Address:</strong> {card.address}</p>
              </div>
            ))}
          </div>
          <button onClick={handleUpload} className="bg-blue-500 text-white p-2 rounded mt-4">Upload Cards</button>
        </div>
      )}
      {isLoading && (
        <div className="mt-4">
          <p>Uploading... {progress.toFixed(2)}%</p>
        </div>
      )}
      {!isLoading && uploadResults.created !== undefined && (
        <div className="mt-4">
          <h2 className="text-2xl mb-4">Upload Summary</h2>
          <p>{uploadResults.created} cards created successfully.</p>
          {uploadResults.errors.length > 0 && (
            <div>
              <h3 className="text-xl mb-2">Errors:</h3>
              <ul className="list-disc list-inside">
                {uploadResults.errors.map((result, index) => (
                  <li key={index}>
                    Error creating card for {result.card.name}: {result.error.message}
                  </li>
                ))}
              </ul>
            </div>
          )}
          <button onClick={() => navigate('/')} className="bg-green-500 text-white p-2 rounded mt-4">Back to Home</button>
        </div>
      )}
    </div>
  );
};

export default BulkCreateCard;