import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import CreateCardForm from '../components/CreateCardForm';
import { useDropzone } from 'react-dropzone';

const CreateCard = () => {
  const { token } = useContext(AuthContext);
  const [contactInfo, setContactInfo] = useState({
    name: '',
    surname: '',
    jobTitle: '',
    email: '',
    phone: '',
    address: ''
  });
  const [customFields, setCustomFields] = useState([{ label: '', value: '' }]);
  const [profilePic, setProfilePic] = useState(null);
  const [coverPic, setCoverPic] = useState(null);
  const [selectedCover, setSelectedCover] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Stato per il caricamento

  const navigate = useNavigate();

  const onDrop = (acceptedFiles, type) => {
    const file = acceptedFiles[0];
    if (type === 'profile') {
      setProfilePic(file);
    } else if (type === 'cover') {
      setCoverPic(file);
    }
  };

  const { getRootProps: getRootPropsProfile, getInputProps: getInputPropsProfile } = useDropzone({
    onDrop: (files) => onDrop(files, 'profile'),
    accept: 'image/*',
  });

  const { getRootProps: getRootPropsCover, getInputProps: getInputPropsCover } = useDropzone({
    onDrop: (files) => onDrop(files, 'cover'),
    accept: 'image/*',
  });

  const handleContactInfoChange = (e) => {
    setContactInfo({ ...contactInfo, [e.target.name]: e.target.value });
  };

  const handleCustomFieldChange = (index, e) => {
    const updatedFields = customFields.map((field, i) =>
      i === index ? { ...field, [e.target.name]: e.target.value } : field
    );
    setCustomFields(updatedFields);
  };

  const handleAddCustomField = () => {
    setCustomFields([...customFields, { label: '', value: '' }]);
  };

  const handleRemoveCustomField = (index) => {
    setCustomFields(customFields.filter((_, i) => i !== index));
  };

  const handleCoverSelectChange = (e) => {
    setSelectedCover(e.target.value);
    setCoverPic(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Mostra il caricamento
    const formData = new FormData();
    formData.append('contactInfo', JSON.stringify(contactInfo));
    formData.append('customFields', JSON.stringify(customFields.filter(field => field.label && field.value)));

    if (profilePic) {
      formData.append('profilePic', profilePic);
    }

    if (coverPic) {
      formData.append('coverPic', coverPic);
    }

    try {
      await axios.post('https://ecard.bildastudio.com/api/cards', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-auth-token': token,
        },
        withCredentials: true
      });
      navigate('/');
    } catch (error) {
      console.error('Error creating card:', error);
    } finally {
      setIsLoading(false); // Nascondi il caricamento
    }
  };

  return (
    <div className="relative container mx-auto p-4">
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 z-50">
          <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-32 w-32"></div>
        </div>
      )}
      <h1 className="text-4xl font-bold mb-4">Create New Card</h1>
      <CreateCardForm
        contactInfo={contactInfo}
        handleContactInfoChange={handleContactInfoChange}
        customFields={customFields}
        handleCustomFieldChange={handleCustomFieldChange}
        handleAddCustomField={handleAddCustomField}
        handleRemoveCustomField={handleRemoveCustomField}
        getRootPropsProfile={getRootPropsProfile}
        getInputPropsProfile={getInputPropsProfile}
        profilePic={profilePic}
        getRootPropsCover={getRootPropsCover}
        getInputPropsCover={getInputPropsCover}
        coverPic={coverPic}
        selectedCover={selectedCover}
        handleCoverSelectChange={handleCoverSelectChange}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default CreateCard;