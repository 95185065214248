import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import CreateCard from './pages/CreateCard';
import BulkCreateCard from './pages/BulkCreateCard';
import EditCard from './pages/EditCard'; // Importa la pagina EditCard
import { AuthProvider } from './context/AuthContext';
import Header from './components/Layout/Header';
import Footer from './components/Layout/Footer';


const App = () => {
  return (
    <AuthProvider>
      <Router>
        <div className="flex flex-col min-h-screen">
          <Header />
          <main className="flex-grow">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/create-card" element={<CreateCard />} />
              <Route path="/bulk-create" element={<BulkCreateCard />} />
              <Route path="/edit/:id" element={<EditCard />} /> {/* Aggiungi questa route */}
              {/* Aggiungi altre rotte protette qui */}
            </Routes>
          </main>
          <Footer />
        </div>
      </Router>
    </AuthProvider>
  );
};

export default App;