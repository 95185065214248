// src/axiosConfig.js
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://ecard.bildastudio.com/',
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true, // Include cookies nelle richieste cross-site
});

// Ottiene il token CSRF e lo imposta come intestazione predefinita
export const getCsrfToken = async () => {
  try {
    const response = await axiosInstance.get('/api/csrf-token');
    axiosInstance.defaults.headers['x-csrf-token'] = response.data.csrfToken;
  } catch (error) {
    console.error('Error fetching CSRF token:', error);
  }
};

// Imposta l'intestazione CSRF per tutte le richieste
getCsrfToken();

export default axiosInstance;