import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [csrfToken, setCsrfToken] = useState('');

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const response = await axios.get('https://ecard.bildastudio.com/api/csrf-token', { withCredentials: true });
        setCsrfToken(response.data.csrfToken);
        axios.defaults.headers.post['x-csrf-token'] = response.data.csrfToken;
        axios.defaults.headers.put['x-csrf-token'] = response.data.csrfToken;
        axios.defaults.headers.delete['x-csrf-token'] = response.data.csrfToken;
      } catch (err) {
        console.error('Error fetching CSRF token:', err);
      }
    };

    fetchCsrfToken();
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser, token, setToken, csrfToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;