import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import EditCardForm from '../components/EditCardForm';
import { useDropzone } from 'react-dropzone';

const EditCard = () => {
  const { token } = useContext(AuthContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const [contactInfo, setContactInfo] = useState({
    name: '',
    surname: '',
    organization: '',
    jobTitle: '',
    email: '',
    phone: '',
    address: ''
  });
  const [customFields, setCustomFields] = useState([{ label: '', value: '' }]);
  const [profilePic, setProfilePic] = useState(null);
  const [coverPic, setCoverPic] = useState(null);
  const [selectedCover, setSelectedCover] = useState('');

  useEffect(() => {
    const fetchCard = async () => {
      try {
        const response = await axios.get(`https://ecard.bildastudio.com/api/cards/${id}`, {
          headers: {
            'x-auth-token': token,
          },
        });
        const card = response.data;
        setContactInfo(card.contactInfo);
        setCustomFields(card.customFields.length > 0 ? card.customFields : [{ label: '', value: '' }]);
        setProfilePic(card.profilePic);
        setCoverPic(card.coverPic);
      } catch (error) {
        console.error('Error fetching card data:', error);
      }
    };

    fetchCard();
  }, [id, token]);

  const onDrop = (acceptedFiles, type) => {
    const file = acceptedFiles[0];
    if (type === 'profile') {
      setProfilePic(file);
    } else if (type === 'cover') {
      setCoverPic(file);
    }
  };

  const { getRootProps: getRootPropsProfile, getInputProps: getInputPropsProfile } = useDropzone({
    onDrop: (files) => onDrop(files, 'profile'),
    accept: 'image/*',
  });

  const { getRootProps: getRootPropsCover, getInputProps: getInputPropsCover } = useDropzone({
    onDrop: (files) => onDrop(files, 'cover'),
    accept: 'image/*',
  });

  const handleContactInfoChange = (e) => {
    setContactInfo({ ...contactInfo, [e.target.name]: e.target.value });
  };

  const handleCustomFieldChange = (index, e) => {
    const updatedFields = customFields.map((field, i) =>
      i === index ? { ...field, [e.target.name]: e.target.value } : field
    );
    setCustomFields(updatedFields);
  };

  const handleAddCustomField = () => {
    setCustomFields([...customFields, { label: '', value: '' }]);
  };

  const handleRemoveCustomField = (index) => {
    setCustomFields(customFields.filter((_, i) => i !== index));
  };

  const handleCoverSelectChange = (e) => {
    setSelectedCover(e.target.value);
    setCoverPic(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('contactInfo', JSON.stringify(contactInfo));
    formData.append('customFields', JSON.stringify(customFields.filter(field => field.label && field.value)));

    if (profilePic && typeof profilePic !== 'string') {
      formData.append('profilePic', profilePic);
    }

    if (coverPic && typeof coverPic !== 'string') {
      formData.append('coverPic', coverPic);
    }

    try {
      await axios.put(`https://ecard.bildastudio.com/api/cards/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-auth-token': token,
        },
        withCredentials: true,
      });
      navigate('/');
    } catch (error) {
      console.error('Error updating card:', error);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-4xl font-bold mb-4">Edit Card</h1>
      <EditCardForm
        contactInfo={contactInfo}
        handleContactInfoChange={handleContactInfoChange}
        customFields={customFields}
        handleCustomFieldChange={handleCustomFieldChange}
        handleAddCustomField={handleAddCustomField}
        handleRemoveCustomField={handleRemoveCustomField}
        getRootPropsProfile={getRootPropsProfile}
        getInputPropsProfile={getInputPropsProfile}
        profilePic={profilePic}
        getRootPropsCover={getRootPropsCover}
        getInputPropsCover={getInputPropsCover}
        coverPic={coverPic}
        selectedCover={selectedCover}
        handleCoverSelectChange={handleCoverSelectChange}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default EditCard;