import React from 'react';
import cover1 from '../assets/images/orgcovers/generic-cover.jpg';
import cover2 from '../assets/images/orgcovers/coverfsi.jpg';

// Importa tutte le altre immagini qui

const coverImages = [
  { name: 'Generic', src: cover1 },
  { name: 'Ferrovie', src: cover2 },
  // Aggiungi tutte le altre immagini qui
];

const CreateCardForm = ({
  contactInfo,
  handleContactInfoChange,
  customFields,
  handleCustomFieldChange,
  handleAddCustomField,
  handleRemoveCustomField,
  getRootPropsProfile,
  getInputPropsProfile,
  profilePic,
  getRootPropsCover,
  getInputPropsCover,
  coverPic,
  selectedCover,
  handleCoverSelectChange,
  handleSubmit
}) => {
  return (
    <form onSubmit={handleSubmit}>
      {/* Contact Information */}
      <div className="mb-4">
        <label className="block text-gray-700">Name</label>
        <input
          type="text"
          name="name"
          value={contactInfo.name}
          onChange={handleContactInfoChange}
          required
          className="w-full p-2 border border-gray-300 rounded"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Surname</label>
        <input
          type="text"
          name="surname"
          value={contactInfo.surname}
          onChange={handleContactInfoChange}
          required
          className="w-full p-2 border border-gray-300 rounded"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Organization</label>
        <input
          type="text"
          name="organization"
          value={contactInfo.organization}
          onChange={handleContactInfoChange}
          required
          className="w-full p-2 border border-gray-300 rounded"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Job Title</label>
        <input
          type="text"
          name="jobTitle"
          value={contactInfo.jobTitle}
          onChange={handleContactInfoChange}
          required
          className="w-full p-2 border border-gray-300 rounded"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Email</label>
        <input
          type="email"
          name="email"
          value={contactInfo.email}
          onChange={handleContactInfoChange}
          required
          className="w-full p-2 border border-gray-300 rounded"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Phone</label>
        <input
          type="text"
          name="phone"
          value={contactInfo.phone}
          onChange={handleContactInfoChange}
          required
          className="w-full p-2 border border-gray-300 rounded"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Address</label>
        <input
          type="text"
          name="address"
          value={contactInfo.address}
          onChange={handleContactInfoChange}
          className="w-full p-2 border border-gray-300 rounded"
        />
      </div>

      {/* Profile Picture Upload */}
      <div className="mb-4">
        <label className="block text-gray-700">Profile Picture</label>
        <div {...getRootPropsProfile({ className: 'dropzone' })} className="border-dashed border-2 border-gray-300 p-4 text-center cursor-pointer">
          <input {...getInputPropsProfile()} />
          {profilePic ? profilePic.name : 'Drag & drop a profile picture or click to select'}
        </div>
      </div>

      {/* Cover Picture Upload or Selection */}
      <div className="mb-4">
        <label className="block text-gray-700">Cover Picture</label>
        <div {...getRootPropsCover({ className: 'dropzone' })} className="border-dashed border-2 border-gray-300 p-4 text-center cursor-pointer">
          <input {...getInputPropsCover()} />
          {coverPic ? coverPic.name : 'Drag & drop a cover picture or click to select'}
        </div>
      </div>

      {/* Cover Picture Predefined Selection */}
      <div className="mb-4">
        <label className="block text-gray-700">Or select a predefined cover</label>
        <select
          value={selectedCover}
          onChange={handleCoverSelectChange}
          className="w-full p-2 border border-gray-300 rounded"
        >
          <option value="">Select a cover</option>
          {coverImages.map((cover, index) => (
            <option key={index} value={cover.src}>
              {cover.name}
            </option>
          ))}
        </select>
      </div>

      {/* Custom Fields */}
      <h2 className="text-2xl mb-4">Custom Fields</h2>
      {customFields.map((field, index) => (
        <div key={index} className="mb-4 flex items-center">
          <input
            type="text"
            name="label"
            value={field.label}
            onChange={(e) => handleCustomFieldChange(index, e)}
            placeholder="Label"
            className="w-1/3 p-2 border border-gray-300 rounded mr-2"
          />
          <input
            type="text"
            name="value"
            value={field.value}
            onChange={(e) => handleCustomFieldChange(index, e)}
            placeholder="Value"
            className="w-2/3 p-2 border border-gray-300 rounded"
          />
          <button
            type="button"
            onClick={() => handleRemoveCustomField(index)}
            className="ml-2 bg-red-500 text-white p-2 rounded"
          >
            Remove
          </button>
        </div>
      ))}
      <button
        type="button"
        onClick={handleAddCustomField}
        className="mb-4 bg-green-500 text-white p-2 rounded"
      >
        Add Custom Field
      </button>

      {/* Submit Button */}
      <button
        type="submit"
        className="w-full bg-blue-500 text-white p-2 rounded"
      >
        Create Card
      </button>
    </form>
  );
};

export default CreateCardForm;